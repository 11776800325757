import React from "react";
import styled, { css } from "styled-components";
import content from "../../content/home.json";
import Section from "../components/Section";
import ContentWrapper from "../components/ContentWrapper";
import GridContainer from "../components/GridContainer";
import { convertMarkdown } from "../utils/helpers";
import { theme } from "../../theme";
import SEO from "../components/SEO";
import Pdf from "../images/x-RD-DOC-A12-10-Technical-Vulnerability-Management-Policy-External.pdf";

export default function IndexPage() {
  return (
    <>
      <SEO title="Vulnerability Reporting" />
      <Section>
        <ContentWrapper>
          <object data={Pdf} type="application/pdf" width="100%" height="800">
            <p>
              Vulnerability Reporting <a href={Pdf}>to the PDF!</a>
            </p>
          </object>
        </ContentWrapper>
      </Section>
    </>
  );
}
