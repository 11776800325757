import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';

const StyledContentWrapper = styled.div`
  padding: var(--spacing-large) 0;

  @media (min-width: ${() => theme.breakPoints.small}) {
    padding: var(--spacing-xlarge) 0;
  }

  @media (min-width: ${() => theme.breakPoints.large}) {
    width: 80%;
    margin: 0 auto;
  }

  @media (min-width: ${() => theme.breakPoints.xxlarge}) {
    width: 70%;
  }

  ${({ extended }) =>
    extended &&
    css`
      padding: calc(var(--spacing-large) + 4vw) 0;

      @media (min-width: ${() => theme.breakPoints.medium}) {
        padding: calc(var(--spacing-xlarge) + 4vw) 0;
      }
    `};
`;

export default function ContentWrapper({ extended, children, className }) {
  return (
    <StyledContentWrapper className={className} extended={extended}>
      {children}
    </StyledContentWrapper>
  );
}
