import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';

const StyledGridContainer = styled.div`
  @media (min-width: ${() => theme.breakPoints.medium}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3.2rem;

    ${({ mb }) =>
      mb &&
      css`
        margin-bottom: var(--spacing-${mb});
      `};
  }
`;

export default function GridContainer({ children, className }) {
  return (
    <StyledGridContainer className={className}>{children}</StyledGridContainer>
  );
}
